import styled from "styled-components"
import {
  Badge,
  Div,
  Flex,
  fontFamilies,
  spacing,
  colors,
  BodyText
} from "@ikiru/talentis-fpc"
import { backgroundOffLimits } from "components/OffLimits/style"

export const PersonWrapper = styled(Flex)`
  flex-direction: column;
  border: 2px solid ${colors.grey.light};
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: ${colors.white.standard};
`

export const PersonalInfoWrapper = styled(Flex)`
  justify-content: space-between;
  border-bottom: 1px solid ${colors.grey.light};
  flex-wrap: wrap;
  width: 100%;
`

export const FlexItemStyled = styled(Flex)<{ isOfflimits?: boolean }>`
  border-right: 1px solid ${colors.grey.light};

  &:nth-child(1) {
    width: 30%;
    ${backgroundOffLimits}
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-child(4) {
    width: 15%;
  }
  &:nth-child(5) {
    border-right: none;
    width: 15%;
  }

  @media (max-width: 768px) {
    &:nth-child(1) {
      ${backgroundOffLimits}
      border-right: 1px solid ${colors.grey.light};
      width: 50%;
    }
    &:nth-child(2) {
      width: 50%;
    }
    &:nth-child(3) {
      border-right: 1px solid ${colors.grey.light};
      width: 50%;
    }
    &:nth-child(4) {
      border-right: none;
      width: 50%;
    }
    &:nth-child(5) {
      border-right: none;
      width: 50%;
    }
    border-right: none;
    border-bottom: 1px solid ${colors.grey.light};
  }
`

type StyledBadgeProps = {
  count: number
}

export const StyledBadge = styled(Badge)<StyledBadgeProps>`
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme, count }) =>
    count === 0 ? theme.colors.white.standard : theme.colors.orange.darkest};
  background: ${({ theme, count }) =>
    count === 0 ? theme.colors.grey.standard : theme.colors.red.lightest};
  height: 16px;
  border-radius: 15%;
  margin-left: 5px;
  padding: 3px;
  font-family: ${fontFamilies.sourceSans};
`

export const StyledFlex = styled(Flex)`
  background-color: ${colors.grey.light};
  color: ${colors.grey.dark};
  padding: 5px;
  justify-content: space-between;
`

export const PersonalNotesWrapper = styled(Flex)`
  padding-left: 65px;
  margin-top: 5px;
  flex-wrap: wrap;
  width: 100%;
`

export const PersonalNoteBioItemStyled = styled(Flex)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey.lightest};
  padding-left: ${spacing.xs}px;
  padding-top: ${spacing.xs}px;
  color: ${({ theme }) => theme.colors.grey.dark};
`

export const NoResultsStyled = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: ${colors.white.standard};
  margin: ${spacing.xl}px ${spacing.s}px;
  padding-top: ${spacing.l}px;
  padding-bottom: ${spacing.xxl}px;
`

export const StyledLocation = styled(BodyText)`
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
  -webkit-line-clamp: 1;
  margin-top: 0;
  margin-bottom: ${spacing.xxs}px;
  padding-right: ${spacing.xs}px;
`

export const RecordFooterTabs = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.xs}px;
`

export const RecordFooterTabItem = styled(Div)<{ active?: boolean }>`
  padding: ${({ theme }) => theme.space.xxs}px
    ${({ theme }) => theme.space.xs}px;
  background: ${({ theme, active }) =>
    active ? theme.colors.grey.lightest : "none"};
  border-radius: 3px 3px 0 0;
  color: ${({ theme, active }) =>
    active ? theme.colors.grey.dark : theme.colors.grey.standard};
  font-size: 13px;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  line-height: 15px;
  cursor: pointer;
`

export const RecordFooterBox = styled(Div)`
  padding: 0 12px 10px 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};
  &:empty {
    padding: 0;
    border: none;
  }
`

export const PersonNoteContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  p,
  span {
    text-align: left;
    font-size: 13px;
  }

  a {
    font-size: 12px;
  }
`
