import { Flex, Link, theme } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const LinkWrapper = styled(Flex)`
  margin-top: 3px;
  margin-right: 11px;
  align-items: center;
`
export const LinkStyled = styled(Link)`
  margin-bottom: 0px;
  margin-left: 4px;
`

export const PersonListContainer = styled(Flex)`
  justify-content: center;
  margin-top: ${theme.space.xs}px;
  padding: ${theme.space.xs}px;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
  height: clamp(120px, auto, 300px);
  max-height: 300px;
  background-color: ${theme.colors.grey.light};
`

export const PersonContainer = styled(Flex)<{ isSelected: boolean }>`
  align-items: center;
  margin-right: ${theme.space.xxs}px;
  margin-bottom: ${theme.space.xxs}px;
  background-color: ${theme.colors.white.standard};
  padding: ${theme.space.xxs}px;
  width: clamp(225px, 230px, 240px);
  height: clamp(100px, 100px, 150px);
  border-radius: 10px;
  border-color: ${theme.colors.green.standard};
  border-width: ${({ isSelected }) => (isSelected ? "2" : "0")}px;
  border-style: solid;
  cursor: pointer;
`
