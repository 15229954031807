import { apiRequest } from "setup/api/api"
import { Note } from "./types"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const generateNoteDate = (note: Note) => {
  if (note.linkCreatedByUser) {
    return {
      ...note,
      createdBy: `${note.linkCreatedByUser?.firstName} ${note.linkCreatedByUser?.lastName}`,
      updatedBy:
        note.linkUpdatedByUser &&
        `${note.linkUpdatedByUser?.firstName} ${note.linkUpdatedByUser?.lastName}`
    }
  } else {
    const noteData = note as Note & { createdByUser: any; updatedByUser: any }
    return {
      ...note,
      createdBy: `${noteData.createdByUser?.firstName} ${noteData.createdByUser?.lastName}`,
      updatedBy:
        noteData.updatedByUser &&
        `${noteData.updatedByUser?.firstName} ${noteData.updatedByUser?.lastName}`
    }
  }
}

export const fetchDocument = async (noteId: string, documentId: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: PersonsEndpoints.NotesDocuments,
    endpointParams: { noteId, documentId },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  if (error) {
    console.error("Error fetching document:", error)
    return null
  }

  return response?.data?.temporaryUrl
}
