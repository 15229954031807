import React, { useCallback, useMemo } from "react"
import { pick } from "lodash"
import {
  Button,
  colors,
  H4,
  Section,
  Flex,
  PencilIcon,
  Line
} from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import {
  EditOffLimitsField,
  OffLimitsData as OffLimitsDataType
} from "components/OffLimits/constants/definitions"
import { OffLimits } from "components/OffLimits/OffLimits"
import { EditOffLimitsData } from "components/OffLimits/OffLimitsDataModal/EditOffLimitsData"
import { OverrideType } from "components/OffLimits/type"
import { InheritedOffLimits } from "components/OffLimits/InheritedOffLimits/InheritedOffLimits"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { InheritedOffLimitsData } from "components/OffLimits/OffLimitsDataModal/InheritedOffLimitsData"
import { isDateGreaterThanToday } from "utils/format-date"
import PersonSectionButton from "../person-components/PersonSectionButton"

type OffLimitsDataProps = {
  onSubmitOffLimits: (values: OffLimitsDataType) => void
  onSubmitInheritedOffLimits: (values: OverrideType[]) => void
}

export const OffLimitsData = ({
  onSubmitOffLimits,
  onSubmitInheritedOffLimits
}: OffLimitsDataProps) => {
  const { offLimits, profileDetails } = usePerson()

  const offLimitsData = useMemo(
    () => pick(offLimits, Object.values(EditOffLimitsField)),
    [offLimits]
  )

  const inheritedOffLimits = useMemo(
    () =>
      profileDetails.experience.value.filter((value) =>
        isDateGreaterThanToday(value.offlimits?.endDate)
      ),
    [profileDetails]
  )

  const { open } = useModal()

  const offLimitsModal = useCallback(() => {
    open(
      ModalNames.EditOffLimits,
      <EditOffLimitsData
        initialValues={offLimitsData}
        onSubmit={onSubmitOffLimits}
      />
    )
  }, [offLimitsData, onSubmitOffLimits, open])

  const inheritedOffLimitsModal = useCallback(() => {
    open(
      ModalNames.EditInheritedOffLimits,
      <InheritedOffLimitsData
        inheritedOffLimits={inheritedOffLimits}
        onSubmit={onSubmitInheritedOffLimits}
      />
    )
  }, [inheritedOffLimits, onSubmitInheritedOffLimits, open])

  return (
    <Section
      header={{
        titleProps: {
          fontWeight: 500,
          as: H4,
          fontSize: "18px !important",
          my: 0,
          p: 0,
          color: colors.grey.dark
        },
        title: messages.offLimits.offLimits,
        actions: (
          <>
            {!offLimits?.endDate && !offLimits?.comment && (
              <PersonSectionButton
                disabled={false}
                label={messages.offLimits.offLimits}
                onClick={offLimitsModal}
              />
            )}
          </>
        )
      }}
    >
      <Flex flexDirection="column" width="100%" py="m">
        <H4 fontSize="18px" m="none" pb="s">
          {messages.offLimits.personOffLimits}
        </H4>
        <OffLimits {...offLimits} editOnClick={offLimitsModal} isSmall />
        {Boolean(inheritedOffLimits.length) && (
          <>
            <Line my="s" />
            <Flex alignItems="center" justifyContent="space-between" pb="xs">
              <H4 fontSize="18px" m="none">
                {messages.offLimits.inheritedCompany}
              </H4>

              <Button
                mode="standard-white"
                size="action-medium"
                onClick={inheritedOffLimitsModal}
              >
                <PencilIcon />
              </Button>
            </Flex>
            {inheritedOffLimits
              .filter((value, index) => {
                const copyIndex = inheritedOffLimits.findIndex(
                  (exp) => exp.companyId === value.companyId
                )
                return copyIndex === index
              })
              .filter((value) => {
                return isDateGreaterThanToday(value.offlimits?.endDate)
              })
              .map((experience, index) => (
                <>
                  <InheritedOffLimits inheritedOffLimits={experience} />
                  {index !== inheritedOffLimits.length - 1 && (
                    <Line mb="xs" mt="xxs" variant="thin" />
                  )}
                </>
              ))}
          </>
        )}
      </Flex>
    </Section>
  )
}
