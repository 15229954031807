import React from "react"
import { Navigate, Route } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import { Persons } from "views/persons/Persons"
import { Homepage } from "views/homepage/Homepage"
import { Signup } from "views/users/signup/Signup"
import { Layout } from "components/visual/layout/Layout"
import { authenticatedRoute } from "setup/router/authenticated-route.hoc"
import { OIDCRedirectRoute } from "setup/auth/OIDCRedirectRoute"
import { CacheBrowserHistory } from "setup/router/CacheBrowserHistory"
import { FinishRegistration } from "views/users/finish-registration/FinishRegistration"
import { Assignments } from "views/assignments/Assignments"
import { VerifySearchFirm } from "views/users/verify-search-firm"
import { unauthenticatedRoute } from "./unauthenticated-route.hoc"
import { BrowserExtensionProvider } from "setup/browser-extension/Provider"
import { Subscription } from "views/subscription"
import { subscriptionRequired } from "./validators/subscription-required"
import { Search } from "views/search/Search"
import { Logout } from "views/logout"
import { NotPermission } from "views/not-permission"
import { Billing } from "views/billing"
import { BrowserRouter } from "react-router-dom"
import { userRolePermissionRoute } from "./user-role-permission-route.hoc"
import { UserRoles } from "setup/auth/module/constants/auth.types"
import { TeamModule } from "views/team/team/TeamModule"
import { ModalModule } from "setup/modal/ModalModule"
import { CustomSwitch } from "./CustomSwitch"
import { Campaigns } from "views/campaigns/Campaigns"
import { Reports } from "views/reports"
import { Support } from "views/support"
import { Outlook } from "views/outlook"
import { ValidatedRoute } from "./ValidatedRoute"
import { Companies } from "views/companies/Companies"
import { CompaniesSearch } from "views/companies/pages/company-search/CompaniesSearch"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { InternalSearch } from "views/internal-search/InternalSearch"
import { TeamRoute } from "views/team/TeamRoute"
import { Settings } from "views/settings/Settings"
import { AttributesPage } from "views/attributes"

export const Router = () => (
  <BrowserExtensionProvider>
    <ModalModule>
      <TeamModule>
        <DndProvider backend={HTML5Backend}>
          <CacheBrowserHistory>
            <Layout>
              <CustomSwitch>
                <Route
                  path={RouterUrl.OIDCRedirectRouteUrl}
                  element={<ValidatedRoute component={OIDCRedirectRoute} />}
                />

                <Route
                  path={RouterUrl.Homepage}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute, subscriptionRequired]}
                      component={Homepage}
                    />
                  }
                />

                <Route
                  path={RouterUrl.VerifySearchFirm}
                  element={
                    <ValidatedRoute
                      validators={[unauthenticatedRoute]}
                      component={VerifySearchFirm}
                    />
                  }
                />

                <Route
                  path={`${RouterUrl.Profile}/*`}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute, subscriptionRequired]}
                      component={Persons}
                    />
                  }
                />

                <Route
                  path={`${RouterUrl.Team}/*`}
                  element={
                    <ValidatedRoute
                      validators={[
                        authenticatedRoute,
                        userRolePermissionRoute(UserRoles.Admin)
                      ]}
                      component={TeamRoute}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Reports}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute]}
                      component={Reports}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Outlook}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute]}
                      component={Outlook}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Support}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute]}
                      component={Support}
                    />
                  }
                />

                <Route
                  path={RouterUrl.RegisterUser}
                  element={<ValidatedRoute component={FinishRegistration} />}
                />

                <Route
                  path={`${RouterUrl.Assignment}/*`}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute, subscriptionRequired]}
                      component={Assignments}
                    />
                  }
                />

                <Route
                  path={`${RouterUrl.Subscription}/*`}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute]}
                      component={Subscription}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Signup}
                  element={
                    <ValidatedRoute
                      validators={[unauthenticatedRoute]}
                      component={Signup}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Search + "/:projectId/:typeOfProject"}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute, subscriptionRequired]}
                      component={Search}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Search}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute, subscriptionRequired]}
                      component={Search}
                    />
                  }
                />

                <Route
                  path={`${RouterUrl.InternalSearchProfile}/*`}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute, subscriptionRequired]}
                      component={InternalSearch}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Billing}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute]}
                      component={Billing}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Logout}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute]}
                      component={Logout}
                    />
                  }
                />

                <Route
                  path={RouterUrl.NotPermission}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute]}
                      component={NotPermission}
                    />
                  }
                />

                <Route
                  path={`${RouterUrl.Campaign}/*`}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute, subscriptionRequired]}
                      component={Campaigns}
                    />
                  }
                />

                <Route
                  path={`${RouterUrl.Company}/*`}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute, subscriptionRequired]}
                      component={Companies}
                    />
                  }
                />

                <Route
                  path={`${RouterUrl.CompaniesSearch}/*`}
                  element={
                    <ValidatedRoute
                      validators={[authenticatedRoute]}
                      component={CompaniesSearch}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Settings}
                  element={
                    <ValidatedRoute
                      validators={[
                        authenticatedRoute,
                        userRolePermissionRoute(UserRoles.Admin)
                      ]}
                      component={Settings}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Attributes}
                  element={
                    <ValidatedRoute
                      validators={[
                        authenticatedRoute,
                        userRolePermissionRoute(UserRoles.Admin)
                      ]}
                      component={AttributesPage}
                    />
                  }
                />

                <Route
                  path={RouterUrl.Index}
                  element={<Navigate to={RouterUrl.Homepage} />}
                />
              </CustomSwitch>
            </Layout>
          </CacheBrowserHistory>
        </DndProvider>
      </TeamModule>
    </ModalModule>
  </BrowserExtensionProvider>
)
