import React, { useCallback } from "react"
import { PlusIcon, colors, NoteLinks, NoteDocuments } from "@ikiru/talentis-fpc"
import { formatDateTime } from "utils/format-date"
import { Note as NoteType } from "components/NoteItem/types"
import { messages } from "setup/messages/messages"
import { generateNoteDate } from "components/NoteItem/helpers"
import { NoteButton, NoteFlexContainer } from "./styles"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { openNoteDoc } from "components/NoteItem/utlis"
import { generateLinks } from "components/Notes/components/forms/utils"
import { useNavigate } from "react-router-dom"
import AttachmentModel from "components/PersonRecords/Notes/NoteModal/AttachmentModel"

type CommentaryListItemProps = {
  note: NoteType
  onEdit: (note?: NoteType) => void
  onCreate?: VoidFunction
}

const CommentaryListItem = ({
  note,
  onEdit,
  onCreate
}: CommentaryListItemProps) => {
  const { open } = useModal()
  const {
    noteTitle,
    noteDescription,
    createdDate,
    updatedDate,
    createdBy,
    updatedBy
  } = generateNoteDate(note)

  const seeDocument = useCallback(
    (documentId: string) => {
      const document = note.documents?.find((doc) => doc.id === documentId)
      if (document) {
        openNoteDoc(document, documentId, note.id, () =>
          open(
            ModalNames.EmailAttachments,
            <AttachmentModel {...{ noteId: note.id, documentId }} />
          )
        )
      }
    },
    [note.documents, note.id, open]
  )
  const navigate = useNavigate()

  return (
    <NoteFlexContainer
      bg="grey.lightest"
      width="100%"
      justifyContent="space-between"
      pt="9px"
      pl="xs"
      pr="xs"
      pb="xxs"
    >
      <NoteLinks
        title={noteTitle}
        description={noteDescription}
        createdDate={createdDate}
        createdBy={createdBy}
        formattedCreatedDate={formatDateTime(createdDate)}
        formattedUpdatedDate={updatedDate ? formatDateTime(updatedDate) : null}
        updatedBy={updatedBy}
        updatedDate={updatedDate}
        moreButtonLabel={messages.person.note.showFullNote}
        lessButtonLabel={messages.person.note.showLessNote}
        linesNumber={4}
        useArrow={true}
        documents={note.documents as unknown as NoteDocuments[]}
        onDocumentClick={seeDocument}
        isHeaderFlexRow
        editModalButton
        isJustifyContent
        openModal={() => onEdit(note)}
        links={generateLinks(note, navigate)}
      />
      {onCreate && (
        <NoteButton
          mode="standard-green"
          size="action-medium"
          onClick={onCreate}
          mr="xs"
        >
          <PlusIcon fill={colors.white.standard} />
        </NoteButton>
      )}
    </NoteFlexContainer>
  )
}

export default CommentaryListItem
