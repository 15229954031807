import React, { useCallback } from "react"
import { NoteDocuments, NoteLinks } from "@ikiru/talentis-fpc"
import { PersonalNoteBioItemStyled, PersonNoteContainer } from "./styles"
import { generateNoteDate } from "components/NoteItem/helpers"
import { formatDateTime } from "utils/format-date"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"
import { openNoteDoc } from "components/NoteItem/utlis"
import { useNavigate } from "react-router-dom"
import { ModalNames } from "setup/modal/modal.definitions"
import AttachmentModel from "components/PersonRecords/Notes/NoteModal/AttachmentModel"
import { generateLinks } from "components/Notes/components/forms/utils"

type PersonNoteProps = {
  note: NoteType
}

export const PersonNote = ({ note }: PersonNoteProps) => {
  const {
    noteTitle,
    noteDescription,
    createdDate,
    updatedDate,
    createdBy,
    updatedBy,
    linkAssignment,
    linkCampaign
  } = generateNoteDate(note)

  const { open } = useModal()

  const seeDocument = useCallback(
    (documentId: string) => {
      const document = note.documents?.find((doc) => doc.id === documentId)
      if (document) {
        openNoteDoc(document, documentId, note.id, () =>
          open(
            ModalNames.EmailAttachments,
            <AttachmentModel {...{ noteId: note.id, documentId }} />
          )
        )
      }
    },
    [note.documents, note.id, open]
  )
  const navigate = useNavigate()

  return (
    <PersonalNoteBioItemStyled pb={linkAssignment || linkCampaign ? "0" : "xs"}>
      <PersonNoteContainer>
        <NoteLinks
          title={noteTitle}
          isSmallTitle
          description={noteDescription}
          createdDate={createdDate}
          createdBy={createdBy}
          formattedCreatedDate={formatDateTime(createdDate)}
          formattedUpdatedDate={
            updatedDate ? formatDateTime(updatedDate) : null
          }
          updatedBy={updatedBy}
          updatedDate={updatedDate}
          moreButtonLabel={messages.person.note.showFullNote}
          lessButtonLabel={messages.person.note.showLessNote}
          linesNumber={2}
          useArrow={true}
          documents={note.documents as unknown as NoteDocuments[]}
          onDocumentClick={seeDocument}
          isHeaderFlexRow
          isJustifyContent
          links={generateLinks(note, navigate)}
        />
      </PersonNoteContainer>
    </PersonalNoteBioItemStyled>
  )
}
