import React from "react"
import { H6 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { Nullable } from "tsdef"
import {
  NoteContainer,
  AddNoteButtonWrapper
} from "components/PersonRecords/Notes/styles"
import CommentaryListItem from "views/assignments/components/candidates-list/components/candidate-record/components/Commentary/Commentary"

type ProjectRecordDetailsPropTypes = {
  project?: any
  personName?: Nullable<string> | undefined
  name: string
  typeTitle: string
  showAddIcon?: boolean
  showMostRecent?: boolean
  outputOnlyLabel?: boolean
  buttonMarginTop?: string
  label?: string
  openModal?: (data?: any) => {}
}

export const ProjectRecordDetails = ({
  project,
  showAddIcon = true,
  showMostRecent = true,
  outputOnlyLabel = false,
  label = "",
  buttonMarginTop = "5px",
  openModal
}: ProjectRecordDetailsPropTypes) => {
  const openNoteModal = openModal

  return (
    <NoteContainer noPadding={true}>
      {project ? (
        <>
          {showMostRecent && (
            <H6 mt="5px">{messages.person.note.mostRecentNote}</H6>
          )}
          <CommentaryListItem
            note={project}
            onEdit={() => openNoteModal?.(project)}
            {...(showAddIcon ? { onCreate: () => openNoteModal?.() } : {})}
          />
        </>
      ) : outputOnlyLabel ? (
        <span onClick={() => openNoteModal?.()}>{label}</span>
      ) : (
        <AddNoteButtonWrapper
          size="extra-small"
          mode="standard-green"
          mt={buttonMarginTop}
          onClick={() => openNoteModal?.()}
        >
          + {messages.person.note.newNote}
        </AddNoteButtonWrapper>
      )}
    </NoteContainer>
  )
}
