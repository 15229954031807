import { useMemo } from "react"
import { useOnAssignmentLoad } from "views/persons/components/person-assignments/hooks/on-assignment-load"
import { useOnCampaignLoad } from "views/persons/components/person-campaigns/hooks/onCampaignLoad"
import { useOnDocumentsLoad } from "views/persons/components/person-documnets/hooks/on-documents-load"
import { useOnNotesLoad } from "views/persons/components/person-notes/hooks/on-notes-load"
import { usePerson } from "views/persons/person-module/candiate-module.context"

const useTabLoader = () => {
  const { candidates, contacts, notes, documents, notesPagination } =
    usePerson()
  const assignmentProps = useOnAssignmentLoad()
  const campaignProps = useOnCampaignLoad()
  const {
    isLoading: isNotesLoading,
    campaignsOptions: campaignNoteOptions,
    assignmentsOptions: assignmentNoteOptions
  } = useOnNotesLoad()
  const { isLoading: isLoadingDocuments } = useOnDocumentsLoad()

  const assignmentLength = useMemo(
    () => Object.values(candidates).length || 0,
    [candidates]
  )
  const campaignLength = useMemo(
    () => Object.values(contacts).length || 0,
    [contacts]
  )
  const noteLength = useMemo(
    () => notesPagination.totalItemCount || 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notesPagination.totalItemCount, notes]
  )

  const documentLength = useMemo(() => documents?.length || 0, [documents])

  return {
    assignmentProps,
    campaignProps,
    isNotesLoading,
    isLoadingDocuments,
    documentLength,
    assignmentLength,
    campaignLength,
    noteLength,
    campaignNoteOptions,
    assignmentNoteOptions
  }
}

export default useTabLoader
